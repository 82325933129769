import React from 'react'
import Img from '../../../../../../src/components/shared/Img'
import H3 from '../../../Headings/H3'
import PropTypes from 'prop-types'
import RichText from '../../../RichText'
import Hyperlink from '../../../HyperLink'

const ColumnToRowItem = ({
  item,
  isMobile,
  isMobileSlider,
  classNames,
  imgClassName,
  title,
}) => {
  const showMobileImg = isMobile && isMobileSlider && item.img.mobileSrc

  return (
    <div
      className={`flex flex-equal flex-col items-center ${classNames}`}
      id={`column-to-row-item-${item.title}`}
      data-testid="column-to-row-item"
    >
      <Img
        src={showMobileImg ? item.img.mobileSrc : item.img.src}
        alt={item.img.alt}
        layout={'responsive'}
        className={`lg:h-20 lg:w-20 ${imgClassName}`}
        styles={{ position: 'relative' }}
      />
      {item.tagline ? (
        <p
          className={`${
            isMobileSlider && isMobile ? 'text-baseline' : 'text-center'
          }  text-13 text-gray-940 lg:mt-6`}
          data-testid="tag-line-item"
        >
          {item.tagline}
        </p>
      ) : null}
      <H3
        text={item.title}
        id={item.title}
        className={`${
          isMobileSlider && isMobile ? 'text-baseline' : 'text-center'
        } mt-5 whitespace-pre-line text-lg font-medium lg:mt-4 lg:text-2xl`}
      />
      <RichText
        contentData={item.description}
        className={`${
          isMobileSlider && isMobile ? 'text-baseline' : 'text-center'
        } mt-4 max-w-80 text-15 leading-170 text-gray-960 lg:mt-6 lg:min-h-16`}
      />
      {item.source ? (
        <p className="mt-4 text-center text-13 text-gray-960">
          Source:
          <Hyperlink
            title={title}
            href={item.source.href}
            childrenClassNames={'text-blue-base'}
          >
            {item.source.text}
          </Hyperlink>
        </p>
      ) : null}
    </div>
  )
}

export default React.memo(ColumnToRowItem)

ColumnToRowItem.propTypes = {
  item: PropTypes.object.isRequired,
  isMobileSlider: PropTypes.bool,
  isMobile: PropTypes.bool.isRequired,
  classNames: PropTypes.string.isRequired,
  title: PropTypes.string,
}
