import React from 'react'
import PropTypes from 'prop-types'
import dynamic from 'next/dynamic'
import LazyLoad from 'react-lazyload'
import useDeviceDetect from '../../../../common/hooks/useDeviceDetect'
import { setDotsClassName } from '../../../../common/helpers'
import ColumnToRowItem from './ColumnToRowItem'

const SlickSlider = dynamic(() => import('../SlickSlider'))

export const ColumnToRowComponent = ({ data, isMobile, isMobileSlider }) =>
  data.map((item, index) => (
    <ColumnToRowItem
      key={index}
      item={item}
      isMobile={isMobile}
      isMobileSlider={isMobileSlider}
      isLastItem={index === data.length - 1}
      classNames="border min-h-132 md:min-h-156 lg:min-h-0 ml-4 lg:mx-0 lg:px-2 px-8"
      imgClassName="w-full"
    />
  ))

const ColumnToRowSection = ({ data, isMobileSlider, sliderName, title }) => {
  const { isMobile } = useDeviceDetect(true)
  const settings = {
    slidesToShow: 1.1,
    slidesToScroll: 1,
    speed: 100,
    dots: true,
    arrows: false,
    afterChange: (current) => {
      setDotsClassName(current, document, `slider-${sliderName}`)
    },
    responsive: [
      {
        breakpoint: 767,
        settings: {
          speed: 100,
          slidesToShow: 1.1,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  }

  return (
    <>
      {isMobile && isMobileSlider ? (
        <LazyLoad
          placeholder={
            <ColumnToRowComponent
              data={data}
              isMobile={isMobile}
              isMobileSlider={isMobileSlider}
            />
          }
        >
          <SlickSlider
            sliderSettings={settings}
            classNames="mt-9"
            name={sliderName}
            dotsClassName="pb-7"
          >
            {data.map((item, index) => (
              <ColumnToRowItem
                key={index}
                item={item}
                isMobile={isMobile}
                isMobileSlider={isMobileSlider}
                isLastItem={index === data.length - 1}
                classNames="border min-h-132 md:min-h-156 lg:min-h-0 ml-4 lg:mx-0 lg:px-2 px-8"
                imgClassName="w-full"
              />
            ))}
          </SlickSlider>
        </LazyLoad>
      ) : (
        <div className="padding-x-primary lg:padding-t-primary gap-x-primary grid grid-cols-4 md:grid-cols-8 lg:grid-cols-12">
          <div className="col-span-full lg:col-span-12">
            <div className="flex flex-col lg:flex-row">
              {data.map((item, index) => (
                <ColumnToRowItem
                  title={title}
                  key={index}
                  item={item}
                  isMobile={isMobile}
                  isMobileSlider={isMobileSlider}
                  classNames="border-t py-10 last:pb-0 first:border-t-0 lg:last:border-r-0 lg:border-r lg:border-t-0 lg:py-0 lg:px-4"
                  imgClassName="h-20 w-20 mx-auto"
                />
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default React.memo(ColumnToRowSection)

ColumnToRowSection.propTypes = {
  data: PropTypes.array.isRequired,
  title: PropTypes.string,
}
